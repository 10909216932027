<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{$t('message.add_floor')}}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <el-button type="primary">Сохранить</el-button> -->
              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{$t('message.save_and_close')}}</el-button>
               <el-button @click="close()">{{$t('message.close')}}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form">
      <el-form ref="form" :model="form" :rules="rules" class="aticler_m" size="small">
        <el-row :gutter="25">
          <el-col :sm="12">
             <el-form-item :label="$t('message.corps')" prop="corps_id">
                  <corpses v-model="form.corps_id" :corps_id="form.corps_id" size="mini"/>
              </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item :label="$t('message.floor')" prop="name">
              <el-input v-model="form.name"/>
            </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>
        <!-- end el-row -->
      </el-form>
    </div>
  </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex';
    import form from '@/utils/mixins/form';
    import corpses from '@/components/selects/corps';
    import drawerChild from '@/utils/mixins/drawer-child';

    export default {
        mixins:[form ,drawerChild],
        data(){
            return {
              loadingButton: false,
              loadingData: false,
            }
        },
        components:{
          corpses
        },
        created(){},
        computed: {
            ...mapGetters({
                rules: 'floors/rules',
                model: 'floors/model',
                columns: 'floors/columns'
            })
        },
        methods:{
            ...mapActions({
                save: 'floors/store',
                empty: 'floors/empty',
            }),
            afterOpened(){
                this.form = JSON.parse( JSON.stringify(this.model) );
            },
            afterClosed(){
              this.empty()
            },
            submit(close = true){
                this.$refs['form'].validate((valid) => {
                    if (valid){
                        this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.loadingButton = false;
                                this.$notify({
                                    title: 'Успешно',
                                    type: "success",
                                    offset: 130,
                                    message: res.message
                                });
                                this.parent().listChanged()
                                if (close == true) {
                                    this.close();
                                }
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                this.$notify({
                                    title: 'Ошибка',
                                    type: "error",
                                    offset: 130,
                                    message: err.error.message
                                });
                            });
                    }
                });
            }
        }
    }
</script>
